import { useState, useEffect } from "react";

import Utils from "../../Services/Utils";

const CardBalanco = ({ data }) => {

    return (

        <div>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Balanco</h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(data.balanco.total)}
                        </h2>
                        <small className="text-secondary">Hoje</small>
                        <h6 >
                            {Utils.toCoin(data.balanco.hoje)}
                            {' '}
                            {
                                data.balanco.hoje != 0 ?
                                    data.balanco.hoje > 0 ?
                                        <i style={{ fontSize: "1em" }} className="bi text-verde bi-arrow-up-circle-fill"></i>
                                        : <i style={{ fontSize: "1em" }} className="bi text-vermelho bi-arrow-down-circle-fill"></i>
                                    : null
                            }
                        </h6>

                    </div>
                    <div className="p-2 flex-fill">
                        {data.balanco.total > 0 ?
                            <i style={{ fontSize: "4em" }} className="bi text-verde bi-arrow-up-circle-fill"></i>
                            : <i style={{ fontSize: "4em" }} className="bi text-vermelho bi-arrow-down-circle-fill"></i>
                        }
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardBalanco;