import { useState, useEffect } from "react";

import moment from "moment/moment";

import PollarisResumeApi from "../../Services/PollarisResumeApi";
import Utils from "../../Services/Utils";
import PollarisResumeService from "../../Services/PollarisResumeService";
import { Link } from "react-router-dom";

const CardSuprimentos = ({ data }) => {

    return (

        <div>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Entradas
                            <Link to="/entradas" className="btn btn-link btn-sm">
                                <i className="bi bi-box-arrow-up-right"></i>
                            </Link>
                        </h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(data.totais.entradas)}
                        </h2>
                        <small className="text-secondary">Hoje</small>
                        <h6 className="text-verde" >{Utils.toCoin(data.hoje.entradas)}</h6>
                    </div>
                    <div className="p-2 flex-fill">
                        <i style={{ fontSize: "4em" }} className="bi text-verde bi-arrow-up-circle"></i>
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardSuprimentos;