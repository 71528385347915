import { useState, useEffect } from "react";

import HomeChart from "../Components/HomeChart";
import TableBoletos from "../Components/Tables/TableBoletos";
import TableSaidas from "../Components/Tables/TableSaidas";
import TableEntradas from "../Components/Tables/TableEntradas";

export default params => {

    document.title = "Pollaris App | Entradas";

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        setLoaded(true);

    }, [])

    return (

        <div>
            <TableEntradas />
        </div>
    )

}