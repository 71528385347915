import { useEffect, useState } from "react"
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";

export default function Pagination({ paginaAtual, totalPagina, onChange }) {


    const [listOfPages = [], setListOfPages] = useState([]);

    useEffect
        (
            () => {

                let pages = [];

                for (let i = 1; i <= totalPagina; i++) {

                    pages.push(i);

                }

                setListOfPages(pages);

                if (pages.length > 20) {

                    setListOfPages([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '...', totalPagina]);
                }

            }, [paginaAtual, totalPagina]
        )

    const changePage = (pagina) => {

        onChange(pagina);

    }

    const upPage = () => {

        if (paginaAtual < totalPagina) {

            onChange(paginaAtual + 1);

        }

    }

    const downPage = () => {

        if (paginaAtual > 1) {

            onChange(paginaAtual - 1);

        }

    }

    const setPage = (pagina) => {

        if (pagina === '...') return;

        onChange(pagina);

    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true" onClick={() => downPage()}>&laquo;</span>
                    </a>
                </li>

                {
                    listOfPages.map((pagina) => {
                        return (
                            <li key={pagina} className={`page-item ${paginaAtual === pagina ? 'active' : ''}`}>
                                <a className="page-link" href="#" onClick={() => setPage(pagina)}>{pagina}</a>
                            </li>
                        )

                    })
                }

                <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true" onClick={() => upPage()}>&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav >
    )

}