import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import { useState } from "react";

import PageBoletos from "./Pages/PageBoletos";

import HomePage from "./Pages/HomePage";

import PageLogin from "./Pages/PageLogin";

import PageRecuperarSenha from "./Pages/PageRecuperarSenha";

import NavBar from "./Components/NavBar";
import PageCadastroDeSistemas from "./Pages/Admin/PageCadastroDeSistemas";
import PollarisResumeService from "./Services/PollarisResumeService";
import PollarisResumeApi from "./Services/PollarisResumeApi";
import PageAuth from "./Pages/PageAuth";
import PageSaidas from "./Pages/PageSaidas";
import PageEntradas from "./Pages/PageEntradas";

function RoutesApp() {

    const service = new PollarisResumeService();

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useState(() => {

        const usuarioLogado = service.getUsuarioLogado();

        if (usuarioLogado) {

            var autenticacao = usuarioLogado;

            const expired = new Date(autenticacao.expire);

            const now = new Date();

            if (now > expired) {

                if (autenticacao.keep) {

                    new PollarisResumeApi().refreshToken();

                } else {

                    localStorage.removeItem("authentication");

                    setIsUserAuthenticated(false);
                }

            } else {

                setIsUserAuthenticated(true);

            }
        }

    });



    return (
        <BrowserRouter>

            {isUserAuthenticated ? (
                <div>
                    <NavBar />
                    <Routes>
                        <Route path="/login" element={<Navigate replace to="/" />} />
                        <Route path="/auth/:bearerToken" element={<Navigate replace to="/" />} />
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/boletos" element={<PageBoletos />} />
                        <Route exact path="/saidas" element={<PageSaidas />} />
                        <Route exact path="/entradas" element={<PageEntradas />} />
                        <Route exact path="/sistemas" element={<PageCadastroDeSistemas />} />
                    </Routes>
                </div>
            ) : (
                <Routes>
                    <Route path="*" element={<Navigate replace to="/login" />} />
                    <Route exact path="/recuperar" element={<PageRecuperarSenha />} />
                    <Route exact path="/recuperar/:recoveryCodeParam" element={<PageRecuperarSenha />} />
                    <Route exact path="/login/" element={<PageLogin />} />
                    <Route exact path="/auth/:bearerToken" element={<PageAuth />} />
                </Routes>
            )}
        </BrowserRouter >
    )
}

export default RoutesApp;