import Utils from "../../Services/Utils";

import { Link } from "react-router-dom";

const CardBoletos = ({ data }) => {


    return (

        <div >
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Boletos
                            <Link to="/boletos" className="btn btn-link btn-sm">
                                <i className="bi bi-box-arrow-up-right"></i>
                            </Link>
                        </h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(data.totais.boletos)}
                        </h2>
                        <small className="text-secondary">Pagar hoje</small>
                        <h6 >{Utils.toCoin(data.hoje.boletos)}</h6>
                    </div>
                    <div className="p-2 flex-fill">
                        <i style={{ fontSize: "4em" }} className="bi bi-upc-scan"></i>
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardBoletos;