import { useState, useEffect } from "react";

import moment from "moment/moment";

import Utils from "../../Services/Utils";

import PollarisResumeApi from "../../Services/PollarisResumeApi";

import MounthSelect from "../Screen/MouthSelect";
import PollarisResumeService from "../../Services/PollarisResumeService";
import Pagination from "../Pagination";

const TableEntradas = (props) => {

    const api = new PollarisResumeApi();

    const service = new PollarisResumeService()

    let loaded = false;

    const sistemaSelecionado = service.getSistemaSelecionado();

    const [dataInicio, setDataInicio] = useState(moment().format(`YYYY-MM-01`));

    const [pagina, setPagina] = useState(1);

    const [dataFim, setDataFim] = useState(moment().format(`YYYY-MM-DD`));

    const [dados, setDados] = useState({ paginas: 0, suprimentos: [] });

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadBoletos(dataInicio, dataFim, 1);

    }, [])

    const loadBoletos = async (dataInicio, dataFim, pagina) => {

        api.get(`movimentacao/${sistemaSelecionado}/suprimentos/${dataInicio}/${dataFim}/${pagina}`).then(data => {

            console.log(data);

            setDados(data);
        });

    }

    const changeMes = (mes, ano) => {

        const dataInicio = `${ano}-${mes}-01`;

        const dataFim = `${ano}-${mes}-${moment(dataInicio).daysInMonth()}`;

        setDataInicio(dataInicio);

        setDataFim(dataFim);

        loadBoletos(dataInicio, dataFim, 1);

    }


    return (

        <div className="container">
            <MounthSelect onChange={(mes, dia) => changeMes(mes, dia)} />

            {
                dados.suprimentos.length === 0
                    ? <h4 className="p-4 text-center border rounded shadow  text-muted rounded-4">Nada encontrado</h4>
                    :
                    < div className="shadow p-4 border rounded-resume">
                        <h5>Entradas</h5>
                        <div className="table-responsive">
                            <Pagination paginaAtual={pagina} totalPagina={dados.paginas} onChange={(pagina) => { setPagina(pagina); loadBoletos(dataInicio, dataFim, pagina); }} />
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Descricao</th>
                                        <th>Forma de Pagamento</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dados.suprimentos.map((item, index) => {
                                        return (
                                            <tr key={index} className="p-4">
                                                <td>{moment(item.dataHora).format("DD/MM/YYYY")}</td>
                                                <td>{item.descricao}</td>
                                                <td>{item.formaPagamento}</td>
                                                <td>{Utils.toCoin(item.valor)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Pagination paginaAtual={pagina} totalPagina={dados.paginas} onChange={(pagina) => { setPagina(pagina); loadBoletos(dataInicio, dataFim, pagina); }} />
                        </div>
                    </div>
            }


        </div >
    )


}

export default TableEntradas;