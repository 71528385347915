import Utils from "../../Services/Utils";
import { Link } from "react-router-dom";

const CardSangrias = ({ data }) => {

    return (

        <div>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Saídas
                            {/*link*/}
                            <Link to="/saidas" className="btn btn-link btn-sm">
                                <i className="bi bi-box-arrow-up-right"></i>
                            </Link>
                        </h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(data.totais.saidas)}
                        </h2>
                        <small className="text-secondary">Hoje</small>
                        <h6 className="text-vermelho" >{Utils.toCoin(data.hoje.saidas)}</h6>
                    </div>
                    <div className="p-2 flex-fill">
                        <i style={{ fontSize: "4em" }} className="bi text-vermelho bi-arrow-down-circle"></i>
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardSangrias;