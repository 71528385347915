import moment from "moment/moment";
import { useEffect, useState } from "react";

import CardBalanco from "./Charts/CardBalanco";
import CardBoletos from "./Charts/CardBoletos";

import CardSangrias from "./Charts/CardSangrias";
import CardSuprimentos from "./Charts/CardSuprimentos";
import MounthSelect from "./Screen/MouthSelect";
import PollarisResumeApi from "../Services/PollarisResumeApi";
import PollarisResumeService from "../Services/PollarisResumeService";

const HomeChart = params => {

    const api = new PollarisResumeApi();

    const service = new PollarisResumeService();

    let load = false;

    const [loading, setLoading] = useState(false);

    const [showBalanco, setShowBalanco] = useState(false);

    const [resume, setResume] = useState({ totais: { boletos: 0, entradas: 0, saidas: 0 }, hoje: { boletos: 0, entradas: 0, saidas: 0 }, balanco: { total: 0, hoje: 0 } });

    const [dataFim, setDataFim] = useState(moment().format(`YYYY-MM-${moment().daysInMonth()}`));

    const [dataInicio, setDataInicio] = useState(moment().format('YYYY-MM-01'));

    useEffect(() => {

        if (load)
            return;

        load = true;

        loadResume();

    }, [])

    const loadResume = async () => {

        api.get(`movimentacao/${service.getSistemaSelecionado()}/resume/${dataInicio}/${dataFim}`).then(data => {

            setResume(data);

            console.log(data);

            setLoading(false);

        })
    }

    const changeMes = (mes, ano) => {

        setDataFim(moment(`${ano}-${mes}-${moment(`${ano}-${mes}`).daysInMonth()}`).format('YYYY-MM-DD'));

        setDataInicio(moment(`${ano}-${mes}-01`).format('YYYY-MM-DD'));

        setLoading(true);

        loadResume();

    }

    return (

        <div className="container">

            <MounthSelect onChange={(mes, ano) => changeMes(mes, ano)} />
            {
                !loading ?
                    <div className="row">

                        <div className="col-md-4 mb-3">
                            <CardBoletos data={resume} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CardSangrias data={resume} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CardSuprimentos data={resume} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CardBalanco data={resume} />
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default HomeChart;